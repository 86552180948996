import React, { useState } from 'react'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import DialogAddChat from './dialog'
import { isMobileMode } from '../../../../../../../services/helpers'
import classnames from 'classnames'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'

export const AddChat = () => {
  const [opened, setOpened] = useState(false)
  const css = useStyles(styles)

  return (
    <div className={css(classnames('add-chat', isMobileMode ? '--mobile' : ''))}>
      <PlateButton onClick={() => setOpened(true)} classes={isMobileMode ? { root: css('button') } : {}}>
        <PlateButtonIcon classes={isMobileMode ? { root: css('button') } : {}}>
          <SvgIconPlus />
        </PlateButtonIcon>
      </PlateButton>
      {opened && <DialogAddChat onCancel={() => setOpened(false)} />}
    </div>
  )
}

AddChat.propTypes = {
}
