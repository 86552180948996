import styles from '../index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { useBanClient } from '../../../shared/useBanClient'
import React, { useContext } from 'react'
import { store } from '../../../../../state/dialogs'
import { useMetaData } from '../../../shared/useMetaData'
import { useSelector } from 'react-redux'
import { Meteor, useTracker, Match } from '../../../../../../meteorAdapter';
import { isMobileMode } from '../../../../../services/helpers'
import { ConversationsPageMobile } from '../ConversationsMobile'
import classnames from 'classnames'
import { LeftPanel } from './left-panel'
import { CenterPanel } from './center-panel'
import { RightPanel } from './right-panel'
import { number } from 'prop-types'

const ConversationsPage = ({ inWorkDialogsCount, newDialogsCount, emptyQueue, setEmptyQueue }) => {
  const css = useStyles(styles)

  const { banResult, clientId, updateBanResult } = useBanClient()

  const { selectedDialogId, dispatch } = useContext(store)

  const { crmFields, relatedChats, recipient, metaDataLoading, setCrmFields, setRecipient } = useMetaData()
  const isDetailsPanelCollapsed = useSelector(({ main }) => main.isDetailsPanelCollapsed)

  useTracker(() => {
    if (Match.isDocumentId(selectedDialogId)) {
      Meteor.subscribe('fetchOneDialogData', selectedDialogId)
    }
  }, [selectedDialogId])

  const onAssignedCurrentUser = () => {
    dispatch({ type: 'setSelectedDialogsTabIndex', selectedDialogsTabIndex: 1 })
  }

  if (isMobileMode) {
    return (
      <ConversationsPageMobile
        inWorkDialogsCount={inWorkDialogsCount}
        newDialogsCount={newDialogsCount}
        onAssignedCurrentUser={onAssignedCurrentUser}
        banResult={banResult}
        clientId={clientId}
        updateBanResult={updateBanResult}
        crmFields={crmFields}
        relatedChats={relatedChats}
        recipient={recipient}
        metaDataLoading={metaDataLoading}
        setCrmFields={setCrmFields}
        setRecipient={setRecipient}
        emptyQueue={emptyQueue}
        setEmptyQueue={setEmptyQueue}
      />
    )
  }

  return (
    <div
      className={css(
        classnames(
          'container',
          isDetailsPanelCollapsed ? '--collapsed' : null
        )
      )}
    >
      <LeftPanel
        inWorkDialogsCount={inWorkDialogsCount}
        newDialogsCount={newDialogsCount}
      />
      {selectedDialogId && (
        <>
          <CenterPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            clientId={clientId}
            onChanged={() => {}}
            onAssignedCurrentUser={onAssignedCurrentUser}
            crmFields={crmFields}
            recipient={recipient}
          />
          <RightPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            crmFields={crmFields}
            relatedChats={relatedChats}
            recipient={recipient}
            metaDataLoading={metaDataLoading}
            setCrmFields={setCrmFields}
            setRecipient={setRecipient}
          />
        </>
      )}
    </div>
  )
}

ConversationsPage.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number
}

export {
  ConversationsPage
}
