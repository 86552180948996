import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { LeftPanel } from '../../Conversations/left-panel'
import { number } from 'prop-types'
import { Header } from '../../../../header'

export const DialogsList = ({ inWorkDialogsCount, newDialogsCount, emptyQueue, setEmptyQueue }) => {
  const css = useStyles(styles)

  return (
    <div className={css('main')}>
      <Header emptyQueue={emptyQueue} setEmptyQueue={setEmptyQueue} />
      <div className={css('container')}>
        <LeftPanel inWorkDialogsCount={inWorkDialogsCount} newDialogsCount={newDialogsCount} />
      </div>
    </div>
  )
}

DialogsList.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number
}
