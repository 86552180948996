import React from 'react'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object, string } from 'prop-types'
import { AgentStatuses } from '../../../../../customStores'
import classnames from 'classnames'
import { useEmptyQueue } from '../../../shared/useEmptyQueue'
import { processError } from '../../../../../services/helpers'
import { IconsCheck } from '@flomni/components/dist/components/widget/kalai-icons/IconsCheck'

export const StatusesPage = ({ profile, agentStatus, onClose, emptyQueue, setEmptyQueue }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const mainClass = css('main')
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const { main } = useDispatch()

  const agentStatuses = useTracker(() => {
    return AgentStatuses.find(
      {
        groupId: currentUserGroupId,
        isActive: true,
        isHidden: false
      },
      {
        fields: {
          status: 1
        }
      }
    ).fetch()
  }, [currentUserGroupId])

  const handlerChangeStatus = (agentStatus) => {
    main.showGlobalLoader(true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { emptyQueueTimeMs, emptyQueueNoActiveChatsMs, emptyQueueSlotsAvailableMs} = useEmptyQueue(emptyQueue, setEmptyQueue)
    Meteor.invoke('users.changeAgentStatus', {
      status: agentStatus,
      emptyQueueTimeMs,
      emptyQueueNoActiveChatsMs,
      emptyQueueSlotsAvailableMs
    })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
    onClose()
  }

  return (
    <div className={mainClass}>
      <div className={css('body')}>
        {agentStatuses.map((status) => (
          <div key={status._id} className={css('statuses-menu-item')}>
            <div
              className={css(classnames('item', agentStatus === status.status ? '--checked' : ''))}
              onClick={() => handlerChangeStatus(status.status)}
            >
              {t(`dlg:agentStatuses.${status.status}`)}
              <div className={css('status')}>{agentStatus === status.status && <IconsCheck />}</div>
            </div>
          </div>
        ))}
        <div
          className={css(classnames('item', '--logout'))}
          onClick={() => {
            onClose()
            Meteor.logout(() => {
              main.logout()
            })
          }}
        >
          {t('dlg:logOut')}
        </div>

      </div>
    </div>
  )
}

StatusesPage.propTypes = {
  agentStatus: string,
  onClose: func.isRequired,
  profile: object
}
