import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ROUTE, routes } from '../../../configs/routes'
import { Conversations } from '../pages/conversations'
import { number } from 'prop-types'

export const DialogsPageMobile = ({ inWorkDialogsCount, newDialogsCount, emptyQueue, setEmptyQueue }) => {
  return (
    <Switch>
      <Route exact path={routes[ROUTE.CONVERSATIONS]}>
        <Conversations
          inWorkDialogsCount={inWorkDialogsCount}
          newDialogsCount={newDialogsCount}
          emptyQueue={emptyQueue}
          setEmptyQueue={setEmptyQueue}
        />
      </Route>
      <Redirect from='*' to={routes[ROUTE.CONVERSATIONS]} />
    </Switch>
  )
}

DialogsPageMobile.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number
}
