import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func, object, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ButtonWithLoader } from '../../../../../../shared/button-with-loader'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { isMobileMode, processError } from '../../../../../../../services/helpers'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { Tip } from '@flomni/components/dist/components/tip'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import classnames from 'classnames'

const BanClientDialog = ({ clientProfile, receiver, onBanned, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const groupId = useSelector(({ main }) => main.currentUserGroupId)

  const TIME = {
    '15minutes': '900',
    '1hour': '3600',
    '15days': '1296000',
    '1year': '31557600'
  }

  const { register, formState, getValues, errors, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      time: TIME['15minutes'],
      note: ''
    }
  })

  const time = watch('time')

  const handlerClickBan = async (event) => {
    event.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    try {
      const values = getValues()
      await Meteor.invoke('clients.banClient', {
        receiver,
        groupId,
        ttl: parseInt(values.time),
        reason: values.note
      })
      setShowLoader(false)
      onBanned()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary' isMobile={isMobileMode}>
      <PopupTitle title={t('dlg:banClientName', { name: clientProfile?.name })} isMobile={isMobileMode} />
      <form className={css(classnames('form', isMobileMode ? '--mobile' : null))}>
        <PopupContent classes={{ root: css('content') }}>
          <Tip variation='secondary' view='info'>
            {t('dlg:blockClientTip')}
          </Tip>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:banFor')}</div>
            <input hidden name='time' ref={register()} />
            <div className={css('radio-group')}>
              {Object.keys(TIME).map((key) => (
                <RadioOption
                  key={key}
                  variation='secondary'
                  onChange={() => setValue('time', TIME[key], { shouldDirty: true })}
                  checked={time === TIME[key]}
                >
                  {t(`dlg:${key}`)}
                </RadioOption>
              ))}
            </div>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:note')}</div>
            <ControlContainer variation='secondary' warning={errors.note ? errors.note.message : null}>
              <Input
                autoFocus
                variation='secondary'
                classes={{ textarea: css('note') }}
                textArea
                placeholder={t('dlg:noteText')}
                warning={!!errors.note}
                name='note'
                ref={register({ required: t('dlg:noteRequired') })}
              />
            </ControlContainer>
          </div>
        </PopupContent>
        <PopupButtons isMobile={isMobileMode}>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='usual'
            text={t('dlg:ban')}
            disabled={!formState.isValid || !formState.isDirty}
            onClick={handlerClickBan}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

BanClientDialog.propTypes = {
  clientProfile: object,
  receiver: string.isRequired,
  onBanned: func.isRequired,
  onCancel: func.isRequired
}

export default BanClientDialog
