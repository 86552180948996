import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, func } from 'prop-types'
import ReminderItem from './reminder-item'
import { isMobileMode } from '../../../../../../services/helpers'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import ReminderForm from './reminder-form'
import { IconsBell } from '@flomni/components/dist/components/widget/kalai-icons/IconsBell'

const RemindersDialog = ({ reminders, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showAddReminder, setShowAddReminder] = useState(false)

  return (
    <Popup open variation='secondary' isMobile={isMobileMode}>
      <PopupTitle title={
        <div className={css('title')}>
          <div>{t('dlg:reminders')}</div>
          <div className={css('close-button')} onClick={onCancel}><SvgIconClose /></div>
        </div>
      } isMobile={isMobileMode} />
      <PopupContent classes={{ root: css('content') }}>
        {reminders.length > 0 ? (
          <div className={css('list')}>
            {reminders.map((reminder) => (
              <ReminderItem key={reminder._id} reminder={reminder} />
            ))}
          </div>
        ) : (
          <div className={css('empty')}>
            <div>
              <div className={css('icon')}><IconsBell /></div>
              <div className={css('alert')}>{t('dlg:youDontHaveReminders')}</div>
              <div className={css('hint')}>{t('dlg:createFirstReminderSeeingHere')}</div>
            </div>
          </div>
        )}
        <div className={css('add-button')} onClick={() => setShowAddReminder(true)}><SvgIconPlus /></div>
        {showAddReminder && (
          <ReminderForm onSave={() => setShowAddReminder(false)} onCancel={() => setShowAddReminder(false)} />
        )}
      </PopupContent>
    </Popup>
  )
}

RemindersDialog.propTypes = {
  reminders: array,
  onCancel: func.isRequired
}

export default RemindersDialog
