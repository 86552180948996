import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ChatItemInfo } from '../shared/chat-item-info'
import { ChatItem } from '../shared/chat-item'
import { array, bool } from 'prop-types'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { store } from '../../../../../../../state/dialogs'
import { isMobileMode } from '../../../../../../../services/helpers'
import classnames from 'classnames'

export const QueueList = ({ chats, loading }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId, dispatch } = useContext(store)

  return (
    <div className={css('queue-body')}>
      <div className={css(classnames('chat-items', isMobileMode ? '--mobile' : null))}>
        {chats.map((chat) => (
          <ChatItem
            key={chat._id}
            chat={chat}
            isPreparedAtTimerVisible
            isInboundCountVisible
            isSelected={selectedDialogId === chat._id}
            onSelect={() => dispatch({ type: 'setSelectedDialog', selectedDialog: chat })}
          />
        ))}
        {!loading && chats.length === 0 && (
          <ChatItemInfo
            icon='clever'
            title={t('dlg:doneAndDone')}
            description={t('dlg:newChatsWillShowHere')}
          />
        )}
        {loading && (
          <div className={css('loader')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

QueueList.props = {
  chats: array,
  loading: bool
}
