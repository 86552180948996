import React from 'react'
import { Meteor, useTracker } from '../../../../meteorAdapter';
import { bool, number, object, func } from 'prop-types'
import { isMobileMode } from '../../../services/helpers'
import { HeaderMobile } from './mobile'
import { HeaderDesktop } from './desktop'

export const Header = ({ dialogsCount, hasSearch, hasAllChats, emptyQueue, setEmptyQueue }) => {
  const { agentStatus, profile, status, doNotDisturb } = useTracker(() => {
    const user = Meteor.user()
    return {
      agentStatus: user?.agentStatus,
      profile: user?.profile,
      status: user?.status,
      doNotDisturb: !!user?.doNotDisturb
    }
  }, [])

  const getProfileName = () => {
    if (profile) {
      const parts = profile.name.trim().split(' ')
      return parts.reduce((acc, item) => acc + item[0], '').substr(0, 2)
    }
    return ''
  }

  return isMobileMode ? (
    <HeaderMobile
      profile={profile}
      status={status}
      doNotDisturb={doNotDisturb}
      getProfileName={getProfileName}
      agentStatus={agentStatus}
      emptyQueue={emptyQueue}
      setEmptyQueue={setEmptyQueue}
    />
  ) : (
    <HeaderDesktop
      dialogsCount={dialogsCount}
      hasAllChats={hasAllChats}
      hasSearch={hasSearch}
      agentStatus={agentStatus}
      profile={profile}
      status={status}
      doNotDisturb={doNotDisturb}
      getProfileName={getProfileName}
      emptyQueue={emptyQueue}
      setEmptyQueue={setEmptyQueue}
    />
  )
}

Header.propTypes = {
  dialogsCount: number,
  hasSearch: bool,
  hasAllChats: bool,
  emptyQueue: object,
  setEmptyQueue: func
}
