import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, func } from 'prop-types'
import ReminderItem from './reminder-item'
import ReminderForm from './reminder-form'
import { isMobileMode } from '../../../../../../../../services/helpers'

const RemindersDialog = ({ reminders, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showAddReminder, setShowAddReminder] = useState(false)

  useEffect(() => {
    if (reminders.length === 0) {
      setShowAddReminder(true)
    }
  }, [])

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary' isMobile={isMobileMode}>
      <PopupTitle title={t('dlg:reminders')} isMobile={isMobileMode} />
      <PopupContent classes={{ root: css('content') }}>
        {!showAddReminder && (
          <div className={css('add-button')}>
            <div className={css('add-button-description')}>{t('dlg:addReminderDescription')}</div>
            <SecondaryButton view='usual' onClick={() => setShowAddReminder(true)}>
              <SecondaryButtonText>{t('dlg:addReminder')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        )}
        {showAddReminder && (
          <div className={css('add-reminder-form')}>
            <ReminderForm
              onSave={() => setShowAddReminder(false)}
              onCancel={() => setShowAddReminder(false)}
            />
          </div>
        )}
        {reminders.length > 0 && (
          <div className={css('list')}>
            {reminders.map((reminder) => (
              <ReminderItem key={reminder._id} reminder={reminder} />
            ))}
          </div>
        )}
      </PopupContent>
      <PopupButtons isMobile={isMobileMode}>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </Popup>
  )
}

RemindersDialog.propTypes = {
  reminders: array,
  onCancel: func.isRequired
}

export default RemindersDialog
