import React from 'react'
import { DialogsProvider } from '../../../../state/dialogs'
import { number } from 'prop-types'
import { ConversationsPage } from './Conversations'

export const Conversations = ({ inWorkDialogsCount, newDialogsCount, emptyQueue, setEmptyQueue }) => {
  return (
    <DialogsProvider isEditMode selectedDialogsTabIndex={1}>
      <ConversationsPage
        inWorkDialogsCount={inWorkDialogsCount}
        newDialogsCount={newDialogsCount}
        emptyQueue={emptyQueue}
        setEmptyQueue={setEmptyQueue}
      />
    </DialogsProvider>
  )
}

Conversations.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number
}
