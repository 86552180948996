import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Avatar } from '../../shared/avatar'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { bool, func, object, string } from 'prop-types'
import { StatusesPage } from './statuses-page'

export const HeaderMobile = ({ profile, status, doNotDisturb, getProfileName, agentStatus, emptyQueue, setEmptyQueue }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className={css('header')}>
      <div className={css(classnames('left', showMenu ? '--open' : ''))} onClick={() => setShowMenu(!showMenu)}>
        <div className={css('user-block')}>
          {profile?.avatarUrl ? (
            <Avatar url={profile?.avatarUrl} isMobileHeader={true} />
          ) : (
            <div className={css('user-logo')}>{getProfileName()}</div>
          )}
          <div className={css(classnames('user-status', status, doNotDisturb ? '--not-disturb' : null))} />
        </div>
        <div className={css('user')}>
          <div className={css('name')}>{profile?.name}</div>
          <div className={css('status')}>{t(`dlg:agentStatuses.${agentStatus}`)}</div>
        </div>
        {showMenu && (
          <StatusesPage
            profile={profile}
            agentStatus={agentStatus}
            onClose={() => setShowMenu(false)}
            emptyQueue={emptyQueue}
            setEmptyQueue={setEmptyQueue}
          />
        )}
      </div>
      <div className={css('right')}>
        {/*<div><IconsSearch /></div>*/}
        {/*<div><IconsBell /></div>*/}
      </div>
    </div>
  )
}

HeaderMobile.propTypes = {
  profile: object,
  status: string,
  doNotDisturb: bool,
  getProfileName: func.isRequired,
  agentStatus: string
}
