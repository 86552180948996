import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { string, func } from 'prop-types'
import { useForm } from 'react-hook-form'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { Tip } from '@flomni/components/dist/components/tip'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { isMobileMode } from '../../../../../../../../../services/helpers'
import classnames from 'classnames'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'

const DialogAddChatFirstStep = ({ onNext, onCancel, initPhone, searchNow }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showNextLoader, setShowNextLoader] = useState(false)

  const { register, formState, watch, setValue, getValues, unregister } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone: initPhone
    }
  })

  const phone = watch('phone')

  useEffect(() => {
    register('phone')

    return () => {
      unregister('phone')
    }
  }, [])

  const onHandlerNext = (e) => {
    e?.preventDefault()
    setShowNextLoader(true)
    const values = getValues()
    Meteor.invoke('dialogs.findRecipientByTransaction', {
      searchType: 'phoneNum',
      searchValue: values.phone
    }).then((response) => {
      setShowNextLoader(false)
      onNext(phone, response)
    }).catch(() => {
      setShowNextLoader(false)
    })
  }

  useEffect(() => {
    if (searchNow) {
      onHandlerNext()
    }
  }, [searchNow])

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <>
      <PopupTitle
        variation='secondary'
        title={
          <div className={css('block')}>
            <div>{t('dlg:addChat')}</div>
            {isMobileMode && <div className={css('icon')} onClick={handlerClickOnCancel}><SvgIconClose /></div>}
          </div>
        }
        classes={{ root: css(classnames('header', isMobileMode ? '--mobile' : '')), title: css('title') }}
      />
      <PopupContent variation='secondary' classes={{ root: css('content') }}>
        {!isMobileMode && <div className={css('progress')}>
          <div className={css('progress-current')} />
          <div className={css('progress-remain')} />
        </div>}
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:phoneNum')}</div>
          <ControlContainer variation='secondary'>
            <Input
              variation='secondary'
              classes={{ root: css('field') }}
              placeholder={t('dlg:phoneNum')}
              value={phone}
              onChange={(e) =>
                setValue('phone', e.target.value, { shouldDirty: true, shouldValidate: true })
              }
            />
            <div className={css('hint')}>{t('dlg:fullFormat')}</div>
          </ControlContainer>
        </div>
        <Tip variation='secondary' view='info' classes={{ root: css('tip') }}>
          <div className={css('tip-description')}>{t('dlg:addChatTip')}</div>
        </Tip>
      </PopupContent>
      <PopupButtons variation='secondary' classes={{ root: css(classnames('buttons', isMobileMode ? '--mobile' : '')) }}>
        <div className={css('right-buttons')}>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            text={t('dlg:find')}
            onClick={onHandlerNext}
            disabled={!formState.isValid || !formState.isDirty}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showNextLoader}
          />
        </div>
      </PopupButtons>
    </>
  )
}

DialogAddChatFirstStep.propTypes = {
  onNext: func.isRequired,
  onCancel: func.isRequired,
  initPhone: string
}

export default DialogAddChatFirstStep
