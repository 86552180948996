import React, { useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Tabs } from '@flomni/components/dist/components/tabs/tabs'
import { TabsHeaderDropdown } from '@flomni/components/dist/components/tabs/tabs-header-dropdown'
import { TabsBody } from '@flomni/components/dist/components/tabs/tabs-body'
import { Tab } from '@flomni/components/dist/components/tabs/tab'
import { Panel } from '@flomni/components/dist/components/tabs/panel'
import { QueueList } from './queue-list'
import { ProgressList } from './progress-list'
import { CompletedList } from './completed-list'
import { Meteor, useTracker } from '../../../../../../../meteorAdapter';
import { Tag } from '@flomni/components/dist/components/tag'
import { store } from '../../../../../../state/dialogs'
import Dialogs from '../../../../../../../collections/client/dialogs'
import { number } from 'prop-types'
import { NoItems } from './no-items'
import { SvgIconQueue } from '@flomni/components/dist/components/svg/feathers/SvgIconQueue'
import { SvgIconTickCircle } from '@flomni/components/dist/components/svg/feathers/SvgIconTickCircle'
import { SvgIconProcess } from '@flomni/components/dist/components/svg/feathers/SvgIconProcess'
import classnames from 'classnames'
import { SortFilterOptions } from './sort-filters-options'
import { isMobileMode } from '../../../../../../services/helpers'
import { useSelector } from 'react-redux'
import { USER_ROLES } from '../../../../../../../constants'
import { AddChat } from './add-chat'

export const LeftPanel = ({ inWorkDialogsCount, newDialogsCount }) => {
  const DIALOG_TYPES = {
    NEW: 'NEW',
    PERSONAL: 'PERSONAL',
    ARCHIVE: 'ARCHIVE',
    LATEST_ARCHIVED: 'LATEST_ARCHIVED'
  }
  const DIALOG_STATES = {
    NEW: 'NEW',
    IN_WORK: 'IN_WORK',
    ARCHIVED: 'ARCHIVED'
  }
  const COUNT_IN_BATCH = 20
  const TAB_TO_TYPE = {
    0: DIALOG_TYPES.NEW,
    1: DIALOG_TYPES.PERSONAL,
    2: DIALOG_TYPES.LATEST_ARCHIVED
  }
  const TAB_TO_STATE = {
    0: DIALOG_STATES.NEW,
    1: DIALOG_STATES.IN_WORK,
    2: DIALOG_STATES.ARCHIVED
  }
  const QUEUE_VIEW_DISABLED = 'queueViewDisabled'
  const ALLOW_FIND_TRANSACTION = 'allowFindTransaction'

  const css = useStyles(styles)
  const { t } = useTranslation()
  const {
    sortOrder,
    sortFieldName,
    filterTags,
    filterStatuses,
    selectedDialogId,
    selectedDialogsTabIndex,
    dispatch
  } = useContext(store)

  const [
    currentUserDepartmentId, currentUserGroupId, currentUserRoles, currentUserPermissions
  ] = useSelector(({ main }) => [
    main.currentUserDepartmentId,
    main.currentUserGroupId,
    main.currentUserRoles,
    main.currentUserPermissions
  ])

  const [dialogType, setDialogType] = useState(DIALOG_TYPES.NEW)
  const [limit, setLimit] = useState(COUNT_IN_BATCH)
  const [isAllowedLoadMore, setIsAllowedLoadMore] = useState(true)
  const isOperator = currentUserRoles.includes(USER_ROLES.OPERATOR)
  const isSupervisor = currentUserRoles.includes(USER_ROLES.SUPERVISOR)
  const [isShow, setIsShow] = useState(false)

  const [listDialogsLoading, listDialogs] = useTracker(() => {
    const status = {}
    if (filterStatuses && filterStatuses.length) {
      filterStatuses.forEach((filterStatus) => {
        status[filterStatus] = true
      })
    }

    const filters = {}
    const otherSelectors = {}
    if (dialogType === DIALOG_TYPES.LATEST_ARCHIVED) {
      otherSelectors.archivingReason = ['manual', 'external']
      filters.period = 'today'
      filters.onlyMyCompletedChats = true
    } else {
      filters.onlyMyNewChats = true
    }
    filters.tags = filterTags ?? []
    filters.status = status

    const handle = Meteor.subscribe('fetchGroupDialogs', {
      user: {
        groupId: currentUserGroupId,
        departmentId: currentUserDepartmentId,
        roles: currentUserRoles
      },
      types: [dialogType],
      limit: dialogType === DIALOG_TYPES.LATEST_ARCHIVED ? 21 : limit,
      sortOrder,
      sortField: sortFieldName,
      filters,
      otherSelectors
    })
    const loading = !handle.ready()

    const chats = Dialogs.find(
      {
        state: TAB_TO_STATE[selectedDialogsTabIndex]
      },
      {
        sort: {
          lastMessageAt: sortOrder
        }
      }
    ).fetch()

    return [loading, chats]
  }, [
    sortOrder,
    sortFieldName,
    filterTags,
    filterStatuses,
    dialogType,
    limit,
    currentUserGroupId,
    currentUserDepartmentId,
    currentUserRoles
  ])

  useTracker(() => {
    const receivers = listDialogs.map(({ receiver }) => receiver)
    Meteor.subscribe('fetchClientsActivity', receivers)
  }, [listDialogs])

  const setTabIndex = (index) => {
    dispatch({ type: 'setSelectedDialogsTabIndex', selectedDialogsTabIndex: index })
  }

  useEffect(() => {
    const type = TAB_TO_TYPE[selectedDialogsTabIndex]
    if (dialogType !== type) {
      setDialogType(type)
      window.setTimeout(() => {
        dispatch({ type: 'setSelectedDialog', selectedDialog: null })
      }, 100)
    }
  }, [selectedDialogsTabIndex])

  useEffect(() => {
    if (!selectedDialogId && !isMobileMode) {
      dispatch({ type: 'setSelectedDialog', selectedDialog: listDialogs.length ? listDialogs[0] : null })
    }
  }, [listDialogs, selectedDialogId])

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight
    if (!bottom) {
      setIsAllowedLoadMore(true)
    }
    if (bottom && !listDialogsLoading && isAllowedLoadMore) {
      setIsAllowedLoadMore(false)
      setLimit(limit + COUNT_IN_BATCH)
    }
  }

  const noItemsToSelect = !selectedDialogId && !listDialogsLoading && listDialogs.length === 0

  useEffect(() => {
    setIsShow(true)
  }, [])

  return (
    <div
      className={css(
        classnames(
          'panel',
          !isMobileMode && noItemsToSelect ? '--wide' : null,
          isMobileMode ? '--mobile' : null
        )
      )}
    >
      <div className={css(classnames('container', isShow || isMobileMode ? '--show' : null))}>
        {isMobileMode ? (
          <div>
            {(currentUserPermissions?.includes(ALLOW_FIND_TRANSACTION) || isSupervisor) && <AddChat />}
          </div>
        ) : (
          <div className={css('header')}>
            <div className={css('wrapper')}>
              <div className={css('title')}>
                {t('dlg:myChats')}
              </div>
              {(currentUserPermissions?.includes(ALLOW_FIND_TRANSACTION) || isSupervisor) && <AddChat />}
            </div>
            <SortFilterOptions />
          </div>
        )}
        <Tabs state={[selectedDialogsTabIndex, setTabIndex]} view='secondary' classes={{ root: css('tabs') }}>
          <TabsHeaderDropdown classes={{ root: css('tabs-header') }}>
            <Tab
              classes={{root: css('tab'), body: css('body'), '--active': css('active')}}
              disabled={isOperator && currentUserPermissions?.includes(QUEUE_VIEW_DISABLED)}
              label={
                newDialogsCount > 0 ? (
                  <Tag color='green' variation='secondary' view='filled'>
                    {newDialogsCount}
                  </Tag>
                ) : null
              }
            >
              {t('dlg:queue')}
            </Tab>
            <Tab
              classes={{root: css('tab'), body: css('body'), '--active': css('active')}}
              label={
                inWorkDialogsCount > 0 ? (
                  <Tag color='green' variation='secondary' view='filled'>
                    {inWorkDialogsCount}
                  </Tag>
                ) : null
              }
            >
              {t('dlg:inProgress')}
            </Tab>
            <Tab classes={{root: css('tab'), body: css('body'), '--active': css('active')}}>{t('dlg:completed')}</Tab>
          </TabsHeaderDropdown>
          <TabsBody classes={{ root: css('tabs-body') }}>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <QueueList chats={listDialogs} loading={listDialogsLoading} />
              </div>
            </Panel>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <ProgressList chats={listDialogs} queueCount={newDialogsCount} loading={listDialogsLoading} />
              </div>
            </Panel>
            <Panel>
              <div className={css('scroll-area')}>
                <CompletedList chats={listDialogs} loading={listDialogsLoading} />
              </div>
            </Panel>
          </TabsBody>
        </Tabs>
      </div>
      {!isMobileMode && noItemsToSelect && selectedDialogsTabIndex === 0 && (
        <NoItems
          Icon={SvgIconQueue}
          title={t('dlg:noChatsInQueue')}
          description={t('dlg:noChatsInQueueDescription')}
        />
      )}
      {!isMobileMode && noItemsToSelect && selectedDialogsTabIndex === 1 && (
        <NoItems
          Icon={SvgIconProcess}
          title={t('dlg:noChatsInProgress')}
          description={t('dlg:noChatsInProgressDescription')}
        />
      )}
      {!isMobileMode && noItemsToSelect && selectedDialogsTabIndex === 2 && (
        <NoItems
          Icon={SvgIconTickCircle}
          title={t('dlg:anyCompletedChatYet')}
          description={t('dlg:anyCompletedChatYetDescription')}
        />
      )}
    </div>
  )
}

LeftPanel.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number
}
