import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { bool, func, object, string } from 'prop-types'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { Tag } from '@flomni/components/dist/components/tag'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { UserAvatarStatus } from '../../../../../../../shared/user-avatar-status'

const AgentItem = ({
  item,
  isInvite,
  currentUserId,
  onInvite,
  onRemove,
  showLoader,
  isDisabled,
  chatName
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)

  const isOwner = item.id === currentUserId
  const name = item.profile?.name || item.id

  if (showRemoveConfirm) {
    return (
      <div className={css('remove-container')}>
        <div className={css('tip')}>
          {isOwner && (
            <Trans
              i18nKey='dlg:leaveChatConfirm'
              values={{ chatName }}
              components={[0, <span className={css('tip-mid')}>1</span>, 2]}
            />
          )}
          {!isOwner && (
            <Trans
              i18nKey='dlg:removeParticipantConfirm'
              values={{ name, chatName }}
              components={[
                0,
                <span className={css('tip-mid')}>1</span>,
                2,
                <span className={css('tip-mid')}>3</span>,
                4
              ]}
            />
          )}
        </div>
        <div className={css('remove-buttons')}>
          <SecondaryButton view='light' onClick={() => setShowRemoveConfirm(false)}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='warning'
            text={isOwner ? t('dlg:leave') : t('dlg:remove')}
            onClick={onRemove}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
            disabled={isDisabled}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={css('container')}>
      <div className={css('agent')}>
        <UserAvatarStatus item={item} />
      </div>
      <div className={css('info')}>
        {!isInvite && (
          <div className={css('role')}>
            {item.id === currentUserId ? t('dlg:owner') : t('dlg:participant')}
          </div>
        )}
        {isInvite && item.status === 'online' && (
          <>
            <Tag color='green' variation='secondary'>
              {t('dlg:online')}
            </Tag>
            {!!item.agentStatus && item.agentStatus !== item.status && !item.doNotDisturb && (
              <Tag color='yellow' variation='secondary' classes={{ root: css('tag') }}>
                {t(`dlg:agentStatuses.${item.agentStatus}`)}
              </Tag>
            )}
          </>
        )}
        {isInvite && item.status === 'offline' && (
          <Tag color='gray' variation='secondary'>
            {t('dlg:offline')}
          </Tag>
        )}
        {isInvite && item.status === 'standby' && (
          <>
            <Tag color='yellow' variation='secondary'>
              {t('dlg:away')}
            </Tag>
            {!!item.agentStatus && item.agentStatus !== item.status && !item.doNotDisturb && (
              <Tag color='yellow' variation='secondary' classes={{ root: css('tag') }}>
                {t(`dlg:agentStatuses.${item.agentStatus}`)}
              </Tag>)
            }
          </>
        )}
        {isInvite && item.doNotDisturb && (
          <Tag color='red' variation='secondary'>
            {t('dlg:doNotDisturb')}
          </Tag>
        )}
      </div>
      {isInvite && (
        <ButtonWithLoader
          cls={css('button')}
          view='usual'
          text={t('dlg:invite')}
          onClick={onInvite}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showLoader}
          disabled={isDisabled}
        />
      )}
      {!isInvite && (
        <SecondaryButton
          classes={{ root: css('button') }}
          view='light'
          onClick={() => {
            setShowRemoveConfirm(true)
          }}
          disabled={isDisabled}
        >
          <SecondaryButtonText>{isOwner ? t('dlg:leave') : t('dlg:remove')}</SecondaryButtonText>
        </SecondaryButton>
      )}
    </div>
  )
}

AgentItem.propTypes = {
  item: object,
  isInvite: bool,
  showLoader: bool,
  isDisabled: bool,
  currentUserId: string,
  onRemove: func,
  onInvite: func,
  chatName: string
}

export default AgentItem
