import React, { useContext, useEffect, useState, useMemo } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { array, func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { Select } from '@flomni/components/dist/components/select'
import { Tip } from '@flomni/components/dist/components/tip'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../../../../../../../state/dialogs'
import { isMobileMode, processError } from '../../../../../../../../services/helpers'
import DialogHelpers from '../../../../../../../../services/dialogHelpers'
import { CheckboxOption } from '@flomni/components/dist/components/checkbox-option'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import classnames from 'classnames'

const CompleteChatDialog = ({ topic, topics, topicsGroup, onComplete, onCancel, crmFields }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)
  const [fields, setFields] = useState([])
  const [immediatelyApplyActions, setImmediatelyApplyActions] = useState(false)
  const [distributionRequest, currentUserArchiveSettings, currentUserSounds] = useSelector(({ main }) => [
    main.distributionRequest,
    main.currentUserArchiveSettings,
    main.currentUserSounds
  ])

  const { register, unregister, formState, getValues, setValue, watch, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      topicId: null
    }
  })

  useEffect(() => {
    register('topicId', { required: topics.length > 0 })

    return () => {
      unregister('topicId')
    }
  }, [])

  useEffect(() => {
    if (topic) {
      setValue('topicId', topic.id || '', { shouldDirty: true, shouldValidate: true })
    }
  }, [topic])

  useEffect(() => {
    fields.forEach((field) => {
      setValue(field._id, field.value || '', { shouldDirty: true, shouldValidate: true })
    })
  }, [fields])

  const topicId = watch('topicId')
  const watchAllFields = watch()

  useEffect(() => {
    const fields = crmFields.filter(
      (field) => !!field.passToStats && field.type !== 'Boolean' && (!field.chatTopics?.length || field.chatTopics.includes(topicId))
    )
    fields.forEach((field) => {
      if (field.valuesList.length > 0) {
        register(field._id, { required: true })
      }
    })
    setFields(fields)

    return () => {
      fields.forEach((field) => {
        if (field.valuesList.length > 0) {
          unregister(field._id)
        }
      })
    }
  }, [crmFields, topicId])

  const handlerClickSave = async (event) => {
    event.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    try {
      const values = getValues()
      if (!topic && values.topicId) {
        const fieldsRes = {}
        fields.forEach((field) => {
          fieldsRes[field.name || field._id] = values[field._id]
        })
        await Meteor.invoke('dialogs.saveDialogSubjects', {
          dialogId: selectedDialogId,
          fields: fieldsRes,
          tagId: values.topicId
        })
      }
      await Meteor.invoke('dialogs.archiveDialog', {
        dialogId: selectedDialogId,
        immediatelyApplyActions
      })

      DialogHelpers.requestHottestDialog(distributionRequest, main, currentUserSounds, 'Complete chat')

      setShowLoader(false)
      onComplete(values.topicId)
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  const [selectedGroup, setSelectedGroup] = useState(null)

  const topicOptions = useMemo(
    () =>
      topics
        .filter((topic) => !selectedGroup || selectedGroup.value === topic.parentId)
        .map((topic) => {
          return {
            label: topic.name,
            value: topic.id
          }
        }),
    [topics, selectedGroup]
  )

  const topicGroupOptions = useMemo(
    () =>
      topicsGroup.map((topic) => {
        return {
          label: topic.name,
          value: topic.id
        }
      }),
    [topicsGroup]
  )

  const selectedTopicOption = topicOptions.find((option) => option.value === topicId) || null
  const hasDelayActions =
    !!currentUserArchiveSettings.delay_actions && !!currentUserArchiveSettings.delay_actions_period

  const delayActions = []
  if (currentUserArchiveSettings.unassign_agents === true) {
    delayActions.push(t('dlg:unassignAllAgents'))
  }
  if (currentUserArchiveSettings.activate_bot === true) {
    delayActions.push(t('dlg:activateBot'))
  }
  if (currentUserArchiveSettings.evaluate_service_quality === true) {
    delayActions.push(t('dlg:rateQualityService'))
  }
  if (currentUserArchiveSettings.send_message === true) {
    delayActions.push(t('dlg:sendMessage'))
  }
  if (currentUserArchiveSettings.send_chatbot_event === true) {
    delayActions.push(t('dlg:startChatbotFromEntryPoint'))
  }

  return (
    <Popup open variation='secondary' isMobile={isMobileMode}>
      <PopupTitle title={t('dlg:completeChat')} isMobile={isMobileMode} />
      <form className={css(classnames('form', isMobileMode ? '--mobile' : null))}>
        <PopupContent classes={{ root: css('content') }}>
          {topicGroupOptions.length > 0 && (
            <div className={css('line')}>
              <div className={css('title')}>{t('dlg:chatTopicGroup')}</div>
              <Select
                isClearable
                variation='secondary'
                className={css('field')}
                options={topicGroupOptions}
                value={selectedGroup}
                menuPortalTarget={document.body}
                placeholder={t('dlg:selectGroup')}
                onChange={(option) => setSelectedGroup(option)}
              />
            </div>
          )}
          {(topicOptions.length > 0 || topicGroupOptions?.length > 0) && (
            <>
              <div className={css('line')}>
                <div className={css('title')}>{t('dlg:chatTopic')}</div>
                <Select
                  variation='secondary'
                  className={css('field')}
                  options={topicOptions}
                  value={selectedTopicOption}
                  menuPortalTarget={document.body}
                  placeholder={t('dlg:selectTopic')}
                  onChange={(option) =>
                    setValue('topicId', option.value, { shouldDirty: true, shouldValidate: true })
                  }
                />
              </div>
              <Tip variation='secondary' view='info' classes={{ root: css('tip') }}>
                {t('dlg:selectTopicTip')}
              </Tip>
            </>
          )}
          {fields.map((field) => (
            <div key={field._id} className={css('line')}>
              <div className={css('title')}>{field.name}</div>
              {['Number', 'String'].includes(field.type) && field.valuesList.length === 0 && (
                <ControlContainer
                  variation='secondary'
                  warning={errors[field._id] ? errors[field._id].message : null}
                >
                  <Input
                    type={field.type === 'Number' ? 'number' : 'text'}
                    variation='secondary'
                    classes={{ root: css('field') }}
                    placeholder={field.name}
                    warning={!!errors[field._id]}
                    name={field._id}
                    ref={register({ required: t('dlg:fieldNameIsRequired', { name: field.name }) })}
                  />
                </ControlContainer>
              )}
              {field.valuesList.length > 0 && (
                <Select
                  variation='secondary'
                  className={css('field')}
                  options={field.valuesList.map((val) => ({ value: val, label: val }))}
                  value={{ value: watchAllFields[field._id], label: watchAllFields[field._id] }}
                  menuPortalTarget={document.body}
                  placeholder={field.name}
                  onChange={(option) =>
                    setValue(field._id, option.value, { shouldDirty: true, shouldValidate: true })
                  }
                  isCreatable={field.allowNotListed}
                  formatCreateLabel={(value) => t('dlg:createNewValueLabel', { value })}
                />
              )}
            </div>
          ))}
          {hasDelayActions && (
            <Tip variation='secondary' view='info' classes={{ root: css('tip') }}>
              <div>{t('dlg:delayedActionAfterChatCompletingEnabled')}</div>
              <div>
                <Trans
                  i18nKey='dlg:delayedActionAfterChatCompletingEnabledDescription'
                  values={{
                    minutes: currentUserArchiveSettings.delay_actions_period,
                    actions: delayActions.join(', ')
                  }}
                  components={[
                    0,
                    <span className={css('tip-mid')}>1</span>,
                    2,
                    <span className={css('tip-mid')}>3</span>,
                    4
                  ]}
                />
              </div>
              <CheckboxOption
                classes={{ root: css('tip-chk') }}
                checked={immediatelyApplyActions}
                variation='secondary'
                onChange={(e) => setImmediatelyApplyActions(e.target.checked)}
              >
                {t('dlg:performImmediatelyUponCompletion')}
              </CheckboxOption>
            </Tip>
          )}
        </PopupContent>
        <PopupButtons isMobile={isMobileMode}>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='usual'
            text={t('dlg:complete')}
            disabled={!formState.isValid}
            onClick={handlerClickSave}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

CompleteChatDialog.propTypes = {
  topic: object,
  topics: array,
  topicsGroup: array,
  onComplete: func.isRequired,
  onCancel: func.isRequired,
  crmFields: array
}

export default CompleteChatDialog
