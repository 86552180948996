import React, { useState, useCallback, useContext, useRef, useEffect } from 'react'
import { Meteor } from '../../../../../../../../../meteorAdapter' 
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, bool, func, object, string } from 'prop-types'
import { Avatar } from '../../../../../../shared/avatar'
import classnames from 'classnames'
import { AttachmentsMessage } from '../attachments'
import { useTranslation } from 'react-i18next'
import { Tag } from '@flomni/components/dist/components/tag'
import { MessageTime } from '../message-time'
import processStrings from 'react-process-string'
import { processUrlConfig } from '../../../../../../../../../common'
import { isMobileMode, processError } from '../../../../../../../../services/helpers'
import dompurify from 'dompurify'
import { useDispatch, useSelector } from 'react-redux'
import { MegabrainTip } from './megabrain'
import ReactDOMServer from 'react-dom/server'
import { getDialogName, fixedEncode } from '../../../../../../../../utils/common'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { SecondaryDropdownItemIcon } from '@flomni/components/dist/components/secondary-dropdown-item/icon'
import { SvgIconChatIdFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconChatIdFilled'
import { SvgIconLanguage } from '@flomni/components/dist/components/svg/feathers/SvgIconLanguage'
import { ROUTE, routes } from '../../../../../../../../configs/routes'
import URI from 'urijs'
import { store } from '../../../../../../../../state/dialogs'

export const InboundMessage = ({
  message,
  hideAvatar,
  showTime,
  chatCaption,
  clientProfile,
  receiver,
  lastInboundMessageAt,
  stuffUsers,
  chatbotEnabled,
  onUpdateMbrain,
  allowSuggestions,
  language
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const stringProcessor = processStrings(processUrlConfig)
  const sanitizer = dompurify.sanitize
  const [showMenu, setShowMenu] = useState(false)
  const { main } = useDispatch()
  const { isEditMode } = useContext(store)
  const hash = window.location.hash
  const messageRef = useRef(null)
  const [isTranslate, setIsTranslate] = useState(false)
  const [translationSettings] = useSelector(({ main }) => [main.currentUserTranslationSettings])

  const attachmentButton = message.attachments.find((attachment) => attachment.type === 'postback')
  const button = attachmentButton ? attachmentButton.button : null
  const otherAttachments = message.attachments?.length
    ? message.attachments.filter((attachment) => !['button', 'postback'].includes(attachment.type))
    : []
  const isLastInbound = lastInboundMessageAt
    ? lastInboundMessageAt.getTime() === message.time.getTime()
    : false

  const messageText = button
    ? button.name
    : message.translation && !isTranslate
    ? message.translation.text
    : message.text

  const conditionsRender = messageText || button || otherAttachments.length

  const renderAvatar = () => {
    if (!hideAvatar) {
      return (
        <Avatar
          url={clientProfile?.avatarUrl}
          cls={css('logo')}
          colorName={getDialogName(chatCaption, clientProfile, receiver, t)}
        />
      )
    }
    if (hideAvatar) {
      return <div className={css('logo')} />
    }

    return null
  }

  const renderStatus = () => {
    return (
      <div className={css(classnames('status', !showTime ? '--show' : null))}>
        {message.isRemoved && (
          <>
            <Tag variation='secondary' view='filled' color='red'>
              {t('dlg:removed')}
            </Tag>
            <div className={css('separator')} />
          </>
        )}
        <div className={css('channel')}>{t(`dlg:channelType.${message.service?.bot}`)}</div>
        {button && (
          <>
            <div className={css('separator')} />
            <Tag variation='secondary' view='filled' color='green'>
              {t('dlg:clickedButton')}
            </Tag>
          </>
        )}
        <div className={css('separator')} />
        {message.translation && (
          <>
            <div onClick={() => setIsTranslate((isTranslate) => !isTranslate)}>
              <Tag variation='secondary' view='filled' color='yellow' classes={{ root: css('tag-clicked') }}>
                <span className={css(classnames(isTranslate ? 'text-bold' : null))}>
                  {message.translation.originalLanguage}
                </span>
                <span className={css('direction')}>{'->'}</span>
                <span className={css(classnames(!isTranslate ? 'text-bold' : null))}>
                  {message.translation.targetLanguage}
                </span>
              </Tag>
            </div>
            <div className={css('separator')} />
          </>
        )}
        <MessageTime message={message} />
      </div>
    )
  }

  useEffect(() => {
    if (`#${+message.time}` === hash) {
      messageRef.current?.scrollIntoView({ block: 'center', behavior: 'auto' })
    }
  }, [])

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  const copyAnchorLink = useCallback(() => {
    try {
      const APP_URL = Meteor.absoluteUrl(routes[ROUTE.SEARCH])
      const link = URI(APP_URL).setQuery('receiver', receiver).toString()
      navigator.clipboard.writeText(`${link}#${+message.time}`)
      main.showSuccessSystemNotification(t('dlg:copied'))
      setShowMenu(false)
    } catch (e) {
      main.showWarningSystemNotification(t('dlg:cantCopy'))
    }
  }, [message])

  const onTranslateMessage = useCallback(async () => {
    try {
      await Meteor.call(
        'messages.translateMessageById',
        {
          messageId: message._id,
          originalLanguage: language,
          targetLanguage: translationSettings.defaultLanguage
        },
        () => {
          setShowMenu(false)
        }
      )
    } catch (err) {
      processError(err, main)
    }
  }, [message])

  return (
    <div
      className={css(
        classnames(
          'container',
          hideAvatar ? null : '--last',
          isMobileMode ? '--mobile' : null,
          !showTime ? '--show' : null
        )
      )}
      ref={messageRef}
    >
      {renderAvatar()}
      <div>
        <div className={css('message-container')}>
          {!message.isRemoved && (
            <div
              className={css(
                classnames(
                  'message',
                  messageText || !conditionsRender ? '--text' : null,
                  `#${+message.time}` === hash ? '--hash' : null
                )
              )}
            >
              {!hideAvatar && messageText && <div className={css('arrow')} />}
              {messageText && (
                <span
                  className={css('text')}
                  dangerouslySetInnerHTML={{
                    __html: ReactDOMServer.renderToString(
                      stringProcessor(fixedEncode(sanitizer(messageText)))
                    )
                  }}
                />
              )}
              {otherAttachments.length > 0 && (
                <AttachmentsMessage attachments={otherAttachments} message={message} isInbound />
              )}
              {!conditionsRender && (
                <span className={css(classnames('text', 'text-italic'))}>{t('dlg:unsupportedContent')}</span>
              )}
            </div>
          )}
          <MegabrainTip
            message={message}
            isLastInbound={isLastInbound}
            stuffUsers={stuffUsers}
            chatbotEnabled={chatbotEnabled}
            onUpdateMbrain={onUpdateMbrain}
            allowSuggestions={allowSuggestions}
          />
          <div>
            <div className={css(classnames('commands', showMenu ? '--opened' : null))}>
              <div>
                <PlateButton noBg hoverType={'usual'} onClick={() => setShowMenu(true)}>
                  <PlateButtonIcon>
                    <SvgIconMenuDots />
                  </PlateButtonIcon>
                </PlateButton>
                <Dropdown
                  active={showMenu}
                  variation='secondary'
                  offsetTarget='iconButton'
                  onOverlayClick={onOverlayClickHandler}
                  position={isMobileMode ? 'right' : 'left'}
                >
                  <SecondaryDropdownItem view='bright' onClick={copyAnchorLink}>
                    <SecondaryDropdownItemIcon>
                      <SvgIconChatIdFilled />
                    </SecondaryDropdownItemIcon>
                    <SecondaryDropdownItemText>{t('dlg:copyAnchorLink')}</SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                  {isEditMode &&
                    !button &&
                    language &&
                    translationSettings?.translationEnabled &&
                    !message?.translation &&
                    translationSettings.defaultLanguage !== language && (
                      <SecondaryDropdownItem view='bright' onClick={onTranslateMessage}>
                        <SecondaryDropdownItemIcon>
                          <SvgIconLanguage />
                        </SecondaryDropdownItemIcon>
                        <SecondaryDropdownItemText>{t('dlg:translateMessage')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    )}
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {renderStatus()}
      </div>
    </div>
  )
}

InboundMessage.propTypes = {
  chatCaption: string,
  clientProfile: object,
  receiver: string,
  message: object,
  hideAvatar: bool,
  showTime: bool,
  lastInboundMessageAt: object,
  stuffUsers: array,
  chatbotEnabled: bool,
  onUpdateMbrain: func.isRequired,
  allowSuggestions: bool,
  language: string
}
