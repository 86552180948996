import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { store } from '../../../../../../../state/dialogs'
import { Meteor, useTracker, Match, Random } from '../../../../../../../../meteorAdapter';
import debounce from 'lodash.debounce'
import UploaderHelpers from '../../../../../../../helpers/uploaderHelpers'
import { processError } from '../../../../../../../services/helpers'
import ChatConsoleHelpers from '../../../../../../../helpers/chatConsoleHelpers'
import DialogHelpers from '../../../../../../../helpers/dialogHelpers'
import { getDialogName } from '../../../../../../../utils/common'
import { useTranslation } from 'react-i18next'

const STOP_TYPING_DELAY_MS = 3 * 1000 // 3s

export const useSend = (
  mbrain,
  actions,
  templates,
  updateTemplates,
  passiveMembers,
  chatCaption,
  clientProfile,
  receiver,
  drafts,
  language
) => {
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId, editingMessage, dispatch } = useContext(store)

  const [isDraftLoaded, setIsDraftLoaded] = useState(false)
  const [message, setMessage] = useState('')
  const [showSendLoader, setShowSendLoader] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showTemplates, setShowTemplates] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [templatesFilter, setTemplatesFilter] = useState(null)
  const [buttonReplaceId, setButtonReplaceId] = useState(null)
  const [readyToSendToMessageId, setReadyToSendToMessageId] = useState(false)
  const [originalText, setOriginalText] = useState(null)
  const [isTranslated, setIsTranslated] = useState(false)
  const currentUserId = Meteor.userId()
  const [translationSettings] = useSelector(({ main }) => [main.currentUserTranslationSettings])
  const [showTranslate, setShowTranslate] = useState(true)

  useEffect(() => {
    if (mbrain && mbrain.topics?.length > 0) {
      setShowSuggestions(true)
    }
  }, [mbrain])

  useEffect(() => {
    if (!isDraftLoaded) {
      const currentUserId = Meteor.userId()
      const text = drafts[currentUserId]?.text
      if (text !== undefined) {
        setMessage(text)
        setIsDraftLoaded(true)
      }
      const attachments = drafts[currentUserId]?.attachments
      if (attachments !== undefined) {
        setAttachments(attachments.filter((attachment) => !!attachment._id))
        setIsDraftLoaded(true)
      }
    }
  }, [drafts, isDraftLoaded])

  useEffect(() => {
    setMessage('')
    setIsDraftLoaded(false)
    setShowSuggestions(false)
    setOriginalText(null)
    setIsTranslated(false)
  }, [selectedDialogId])

  const _handleStopTyping = () => {
    if (Match.isDocumentId(selectedDialogId)) {
      Meteor.emitServerEvent('dialogs.dialog.stopTyping', { dialogId: selectedDialogId })
    }
  }

  const _handlePassiveMemberActivity = () => {
    if (Match.isDocumentId(selectedDialogId)) {
      Meteor.emitServerEvent('dialogs.dialog.detectedPassiveMemberActivity', {
        dialogId: selectedDialogId
      })
    }
  }

  const _saveMessageDraft = (dialogId, data) => {
    if (Match.isDocumentId(dialogId)) {
      Meteor.emitServerEvent('dialogs.dialog.saveMessageDraft', {
        ...data,
        dialogId
      })
    }
  }

  const cleanMessageDraft = () => {
    Meteor.emitServerEvent('dialogs.dialog.cleanMessageDraft', {
      dialogId: selectedDialogId,
      showSuggestions
    })
  }

  const handleStopTyping = debounce(_handleStopTyping, STOP_TYPING_DELAY_MS)
  const handlePassiveMemberActivity = debounce(_handlePassiveMemberActivity, 100)
  const saveMessageDraft = debounce(_saveMessageDraft, 400)

  const saveDraft = (data) => {
    setIsDraftLoaded(true)
    const newAttachments = data.attachments || attachments
    saveMessageDraft(selectedDialogId, {
      ...data,
      text: data.text ?? message,
      attachments: newAttachments.filter((attachment) => attachment.type !== 'file')
    })
  }

  useEffect(() => {
    if (editingMessage) {
      const text = editingMessage.text
      const attachments = extractAttachmentsFromMessage(editingMessage)
      setMessage(text)
      setAttachments(attachments)
      saveDraft({ text, attachments })
    }
  }, [editingMessage])

  const buttons = useTracker(() => {
    const currentUserId = Meteor.userId()
    const user = Meteor.users.findOne(currentUserId, {
      fields: {
        buttons: 1
      }
    })
    return user?.buttons || []
  }, [])

  const extractAttachmentsFromMessage = (message) => {
    const attachments = []
    message.attachments.forEach((attachment) => {
      if (attachment.type === 'location') {
        attachments.push({
          _id: Random.id(),
          location: {
            name: attachment.name,
            lat: attachment.lat,
            lng: attachment.lng
          },
          type: 'location'
        })
      }
      if (attachment.type === 'contact') {
        attachments.push({
          _id: Random.id(),
          contact: {
            name: attachment.name,
            phone: attachment.value
          },
          type: 'contact'
        })
      }
      if (attachment.type === 'button') {
        attachments.push({
          _id: Random.id(),
          button: {
            ...attachment
          },
          type: 'button'
        })
      }
      if (attachment.type === 'file' || attachment.type === 'image') {
        attachments.push({
          _id: Random.id(),
          file: {
            name: attachment.name,
            size: attachment.size,
            type: 'file',
            url: attachment.url,
            fileId: attachment.fileId
          },
          type: 'file'
        })
      }
    })
    return attachments
  }

  const getAttachmentToSend = async (attachment) => {
    if (attachment.type === 'button') {
      return {
        ...attachment.button,
        type: 'button'
      }
    }
    if (attachment.type === 'contact') {
      return {
        name: attachment.contact.name,
        value: attachment.contact.phone,
        kind: 'phone',
        type: 'contact'
      }
    }
    if (attachment.type === 'location') {
      return {
        name: attachment.location.name,
        lat: attachment.location.lat,
        lng: attachment.location.lng,
        type: 'location'
      }
    }
    if (attachment.type === 'file') {
      const isImage = /^image\//g.test(attachment.file.type)
      try {
        const { url, fileId } = await uploadAttachmentFile(isImage, attachment)
        return {
          name: attachment.file.name,
          size: attachment.file.size,
          url,
          fileId,
          type: isImage ? 'image' : 'file'
        }
      } catch (e) {
        console.log('upload error', e)
      }
    }
    return null
  }

  const uploadAttachmentFile = async (isImage, attachment) => {
    if (attachment.file.url && attachment.file.fileId) {
      return Promise.resolve({
        url: attachment.file.url,
        fileId: attachment.file.fileId
      })
    }
    const { url, fileId } = isImage
      ? await UploaderHelpers.uploadImage(currentUserId, selectedDialogId, attachment.file)
      : await UploaderHelpers.uploadFile(currentUserId, selectedDialogId, attachment.file)
    return { url, fileId }
  }

  const getAttachmentsToSend = async () => {
    return Promise.all(attachments.map((attachment) => getAttachmentToSend(attachment))).then((items) =>
      items.filter((item) => !!item)
    )
  }

  const getBotAction = () => {
    const actionAttachment = attachments.find((attachment) => attachment.type === 'action')
    if (actionAttachment) {
      return {
        id: actionAttachment.action.actionId,
        name: actionAttachment.action.name
      }
    }
    return null
  }

  const onCancelEditing = (e) => {
    e.preventDefault()
    dispatch({ type: 'setEditingMessage', editingMessage: null })
  }

  const onUpdate = (e) => {
    e.preventDefault()
    applyTemplateBeforeSend()
    setReadyToSendToMessageId(editingMessage._id)
  }

  const onSend = (e) => {
    e.preventDefault()
    applyTemplateBeforeSend()
    setReadyToSendToMessageId(null)
  }

  const onSendTranslate = (e) => {
    e.preventDefault()
    setIsTranslated(true)
    onSend(e)
  }

  const applyTemplateBeforeSend = () => {
    const text = message.trim()
    if (text.length && text[0] === '/') {
      const templates = getTemplates(true)
      if (templates.length === 1) {
        onTemplateSelected(templates[0])
      }
    }
  }

  useEffect(() => {
    const send = async () => {
      await onSendMessage(readyToSendToMessageId)
      if (readyToSendToMessageId) {
        dispatch({ type: 'setEditingMessage', editingMessage: null })
      }
      setReadyToSendToMessageId(false)
    }

    if (readyToSendToMessageId !== false) {
      send()
    }
  }, [readyToSendToMessageId])

  const onSendMessage = async (messageId) => {
    const text = message.trim()
    if (text.length) {
      setShowSendLoader(true)
      const attachments = await getAttachmentsToSend()
      const params = {
        dialogId: selectedDialogId,
        text,
        attachments
      }
      if (messageId) {
        params.messageId = messageId
      }
      const botAction = getBotAction()
      if (botAction) {
        params.botAction = botAction
      }
      if (isTranslated) {
        params.originalLanguage = translationSettings.defaultLanguage
        params.targetLanguage = language
      }
      try {
        await Meteor.invoke(messageId ? 'messages.updateMessage' : 'messages.appendMessage', params)
        setMessage('')
        setAttachments([])
        cleanMessageDraft()
        setShowEmojiPicker(false)
        setShowActions(false)
        setOriginalText(null)
        setIsTranslated(false)
        closeButtons()
        closeTemplates()
      } catch (err) {
        processError(err, main)
      } finally {
        setShowSendLoader(false)
      }
    }
  }

  const onShowTranslate = async () => {
    const text = message.trim()
    if (text.length) {
      setShowSendLoader(true)
      try {
        await Meteor.call('messages.translateMessage', {
          originalLanguage: translationSettings.defaultLanguage,
          targetLanguage: language,
          text
        }, (err, res) => {
          if (!err && res) {
            setOriginalText(text)
            setMessage(res)
          }
        })
      } catch (err) {
        processError(err, main)
      } finally {
        setShowSendLoader(false)
      }
    }
  }

  const onShowOriginal = (e) => {
    e.preventDefault()
    setMessage(originalText)
    setOriginalText(null)
  }

  const onChangeText = (e) => {
    handleStopTyping()

    if (passiveMembers.includes(currentUserId)) {
      handlePassiveMemberActivity()
    }

    const text = e.target.value
    setMessage(text)
    saveDraft({ text })
  }

  const handleEnter = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !showSendLoader) {
      if (editingMessage) {
        await onUpdate(e)
      } else {
        if (language && (translationSettings?.defaultLanguage !== language) && !originalText && showTranslate) {
          await onSendTranslate(e)
        } else {
          await onSend(e)
        }
      }
    }
  }

  const handlePaste = (event) => {
    const items = ChatConsoleHelpers.getClipboardImageItems(event)
    const attachments = items.map((item) => {
      return {
        _id: Random.id(),
        type: 'file',
        file: item.getAsFile()
      }
    })
    if (attachments.length) {
      handlerOnAddAttachments(attachments)
    }
  }

  const handleTemplates = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      const position = e.target.selectionEnd
      e.target.value = e.target.value.substring(0, position) + '\n' + e.target.value.substring(position)
      e.target.selectionEnd = position + 1
      return
    }
    if (showTemplates && e.target.value && e.target.value[0] === '/') {
      const filter = e.target.value.substr(1)
      setTemplatesFilter(filter ?? null)
    }
    if (e.target.value === '/') {
      openTemplates()
    }
    if (e.target.value === '' && showTemplates) {
      closeTemplates()
    }
  }

  const handlerOnAddAttachments = (items) => {
    const newAttachments = [...attachments, ...items]
    setAttachments(newAttachments)
    saveDraft({ attachments: newAttachments })
  }

  const handlerOnDeleteAttachment = (id) => {
    const newAttachments = attachments.filter((attachment) => attachment._id !== id)
    setAttachments(newAttachments)
    saveDraft({ attachments: newAttachments })
  }

  const handlerOnChangeFileAttachment = (id, file) => {
    const newAttachments = attachments.map((attachment) => {
      if (attachment.type === 'file' && attachment._id === id) {
        return {
          ...attachment,
          file
        }
      }
      return attachment
    })
    setAttachments(newAttachments)
    saveDraft({ attachments: newAttachments })
  }

  const handlerOnChangeContactAttachment = (id, contact) => {
    const newAttachments = attachments.map((attachment) => {
      if (attachment.type === 'contact' && attachment._id === id) {
        return {
          ...attachment,
          contact
        }
      }
      return attachment
    })
    setAttachments(newAttachments)
    saveDraft({ attachments: newAttachments })
  }

  const handlerOnChangeLocationAttachment = (id, location) => {
    const newAttachments = attachments.map((attachment) => {
      if (attachment.type === 'location' && attachment._id === id) {
        return {
          ...attachment,
          location
        }
      }
      return attachment
    })
    setAttachments(newAttachments)
    saveDraft({ attachments: newAttachments })
  }

  const handlerOnChangeAction = () => {
    openActions()
  }

  const handlerOnChangeButton = (button) => {
    openButtons()
    setButtonReplaceId(button.id)
  }

  const onTemplateSelected = (template) => {
    const text = DialogHelpers.populateTemplatePlaceholders(template.text, {
      clientName: getDialogName(chatCaption, clientProfile, receiver, t)
    })
    const newAttachments = [...attachments]
    if (template.attachments?.length) {
      template.attachments.forEach((attachment) => {
        if (attachment.type === 'button') {
          const addedButton = newAttachments.find(
            (attach) => attach.type === 'button' && attach.button.id === attachment.id
          )
          if (!addedButton) {
            newAttachments.push({
              _id: attachment.id,
              type: 'button',
              button: attachment
            })
          }
        }
      })
    }
    setMessage(text)
    setAttachments(newAttachments)
    saveDraft({ text, attachments: newAttachments, selectedTemplateId: template.id })
    closeTemplates()
  }

  const onActionSelected = (action) => {
    const addedAction = attachments.find((attachment) => attachment.type === 'action')
    if (addedAction) {
      const newAttachments = attachments.map((attachment) => {
        if (attachment.type === 'action') {
          return {
            ...attachment,
            action
          }
        }
        return attachment
      })
      setAttachments(newAttachments)
      saveDraft({ attachments: newAttachments })
    } else {
      const newAttachments = [
        ...attachments,
        {
          _id: action.actionId,
          type: 'action',
          action
        }
      ]
      setAttachments(newAttachments)
      saveDraft({ attachments: newAttachments })
    }
    setShowActions(false)
  }

  const onButtonSelected = (button) => {
    if (
      buttonReplaceId &&
      attachments.find(
        (attachment) => attachment.type === 'button' && attachment.button.id === buttonReplaceId
      )
    ) {
      const newAttachments = attachments.map((attachment) => {
        if (attachment.type === 'button' && attachment.button.id === buttonReplaceId) {
          return {
            ...attachment,
            _id: button.id,
            button
          }
        }
        return attachment
      })
      setAttachments(newAttachments)
      saveDraft({ attachments: newAttachments })
    } else {
      const addedButton = attachments.find(
        (attachment) => attachment.type === 'button' && attachment.button.id === button.id
      )
      if (!addedButton) {
        const newAttachments = [
          ...attachments,
          {
            _id: button.id,
            type: 'button',
            button
          }
        ]
        setAttachments(newAttachments)
        saveDraft({ attachments: newAttachments })
      }
    }
    closeButtons()
  }

  const onSelectEmoji = (emoji) => {
    const text = message + emoji.native
    setMessage(text)
    saveDraft({ text })
  }

  const closeTemplates = () => {
    setShowTemplates(false)
    setTemplatesFilter(null)
  }

  const openTemplates = () => {
    setShowTemplates(true)
    setShowButtons(false)
    setShowActions(false)
    setShowEmojiPicker(false)
  }

  const toggleTranslate = () => {
    setShowTranslate((showTranslate) => !showTranslate)
  }

  const openButtons = () => {
    setShowButtons(true)
    setShowTemplates(false)
    setShowActions(false)
    setShowEmojiPicker(false)
  }

  const openActions = () => {
    setShowActions(true)
    setShowButtons(false)
    setShowTemplates(false)
    setShowEmojiPicker(false)
  }

  const openEmojiPicker = () => {
    setShowEmojiPicker(true)
    setShowActions(false)
    setShowButtons(false)
    setShowTemplates(false)
  }

  const getTemplates = (exactMatch = false) => {
    if (templatesFilter) {
      let searchInText = false
      let lowerCaseTemplatesFilter = exactMatch ? templatesFilter : templatesFilter.toLowerCase()
      if (lowerCaseTemplatesFilter && lowerCaseTemplatesFilter[0] === '/') {
        lowerCaseTemplatesFilter = lowerCaseTemplatesFilter.substr(1)
        searchInText = true
      }
      return templates.filter((template) => {
        const name = template.title || template.name || ''
        const lowerCaseName = exactMatch ? name : name.toLowerCase()
        const lowerCaseText = exactMatch ? template.text : (template.text || '').toLowerCase()
        return lowerCaseName.indexOf(lowerCaseTemplatesFilter) !== -1 ||
          (searchInText && lowerCaseText.indexOf(lowerCaseTemplatesFilter) !== -1)
      })
    }
    return templates
  }

  const getButtons = () => {
    const addedButtonIds = attachments
      .filter((attachment) => attachment.type === 'button')
      .map((attachment) => attachment._id)
    return buttons.filter((button) => !addedButtonIds.includes(button.id))
  }

  const closeButtons = () => {
    setShowButtons(false)
    setButtonReplaceId(null)
  }

  return {
    message,
    showSendLoader,
    attachments,
    showEmojiPicker,
    showTemplates,
    showSuggestions,
    showActions,
    setShowSuggestions,
    showButtons,
    buttons,
    setShowActions,
    setShowEmojiPicker,
    editingMessage,
    getTemplates,
    onTemplateSelected,
    onButtonSelected,
    handlerOnAddAttachments,
    closeButtons,
    openButtons,
    openActions,
    closeTemplates,
    openTemplates,
    onUpdate,
    onSend,
    handlePaste,
    onChangeText,
    handlerOnDeleteAttachment,
    handlerOnChangeFileAttachment,
    handlerOnChangeContactAttachment,
    handlerOnChangeLocationAttachment,
    handlerOnChangeAction,
    handlerOnChangeButton,
    onSelectEmoji,
    onActionSelected,
    getButtons,
    handleEnter,
    handleTemplates,
    openEmojiPicker,
    onCancelEditing,
    onShowTranslate,
    onShowOriginal,
    originalText,
    onSendTranslate,
    showTranslate,
    toggleTranslate,
    translationSettings
  }
}
