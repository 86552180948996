import React, { useState, useContext, useEffect } from 'react'
import { Meteor, useTracker } from '../../../../../../../../meteorAdapter'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, bool, array, string } from 'prop-types'
import { DialogReminders, Tags } from '../../../../../../../customStores'
import classnames from 'classnames'
import { processError } from '../../../../../../../services/helpers'
import { PERMISSIONS_PAGE } from '../../../../../../../services/constants'
import { USER_ROLES } from '../../../../../../../../constants'
import BanClientDialog from '../../../Conversations/right-panel/ban-client-dialog'
import Dialogs from '../../../../../../../../collections/client/dialogs'
import { store } from '../../../../../../../state/dialogs'
import DialogHelpers from '../../../../../../../services/dialogHelpers'
import CompleteChatDialog from '../../../Conversations/center-panel/header/complete-chat-dialog'
import ReopenChatDialog from '../../../Conversations/center-panel/header/reopen-chat-dialog'
import { ROUTE, routes } from '../../../../../../../configs/routes'
import URI from 'urijs'
import AgentsDialog from '../../../Conversations/center-panel/header/agents-dialog'
import RemindersDialog from '../../reminders-dialog'

export const Menu = ({ state, banResult, updateBanResult, setShowMenu, crmFields, subjects, stuffUsers }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const mainClass = css('main')
  const { main } = useDispatch()
  const { selectedDialogId, isEditMode, dispatch } = useContext(store)
  const [topic, setTopic] = useState(null)
  const [showReopenDialog, setShowReopenDialog] = useState(false)
  const [
    currentUserGroupId,
    currentUserPermissions,
    currentUserRoles,
    currentUserArchiveSettings,
    distributionRequest,
    currentUserSounds
  ] = useSelector(({ main }) => [
    main.currentUserGroupId,
    main.currentUserPermissions,
    main.currentUserRoles,
    main.currentUserArchiveSettings,
    main.distributionRequest,
    main.currentUserSounds
  ])
  const { receiver, clientProfile, chatCaption } = useTracker(() => {
    const dialog = Dialogs.findOne(selectedDialogId, {
      fields: {
        clientId: 1,
        comments: 1,
        receiver: 1,
        stuffInfo: 1,
        chatCaption: 1,
        clientProfile: 1
      }
    })
    const comments = dialog?.comments || []
    comments.sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1))
    return { ...dialog, comments }
  }, [selectedDialogId])
  const isSupervisor = currentUserRoles.includes(USER_ROLES.SUPERVISOR)
  const [showBanClient, setShowBanClient] = useState(false)
  const [showCompleteDialog, setShowCompleteDialog] = useState(false)
  const [showAgentsDialog, setShowAgentsDialog] = useState(false)
  const [showRemindersDialog, setShowRemindersDialog] = useState(false)
  const topics = useTracker(() => {
    return Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group',
        kind: 'session'
      },
      {
        fields: {
          name: 1,
          parentId: 1
        }
      }
    ).map(({ _id, name, parentId }) => ({
      id: _id,
      name,
      parentId
    }))
  }, [])

  const topicsGroup = useTracker(() => {
    return Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group',
        kind: 'sessionGroup'
      },
      {
        fields: {
          name: 1
        }
      }
    ).map(({ _id, name }) => ({
      id: _id,
      name
    }))
  }, [])

  useEffect(() => {
    setTopic(topics.find((topic) => subjects === topic.id) || null)
  }, [topics, subjects])

  const onUnbanClient = () => {
    setShowMenu(false)
    main.showGlobalLoader(true)
    Meteor.invoke('clients.unbanClient', {
      receiver,
      groupId: currentUserGroupId
    })
      .then(() => {
        updateBanResult()
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }

  const handlerClickComplete = async () => {
    const hasDelayActions =
      !!currentUserArchiveSettings.delay_actions && !!currentUserArchiveSettings.delay_actions_period
    const hasCrmFieldsToFill = crmFields.length && crmFields.some((field) => !!field.passToStats)
    if ((topics && topics.length) || hasDelayActions || hasCrmFieldsToFill) {
      setShowCompleteDialog(true)
    } else {
      main.showGlobalLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      try {
        await Meteor.invoke('dialogs.archiveDialog', {
          dialogId: selectedDialogId
        })

        DialogHelpers.requestHottestDialog(distributionRequest, main, currentUserSounds, 'Archive chat')

        main.showGlobalLoader(false)
        afterCompleteDialog()
      } catch (err) {
        main.showGlobalLoader(false)
        processError(err, main)
      }
    }
  }

  const reminders = useTracker(() => {
    Meteor.subscribe('reminders.activeReminders', selectedDialogId)
    return DialogReminders.find(
      {
        dialogId: selectedDialogId,
        isActive: true
      },
      {
        sort: {
          intendedAt: 1
        }
      }
    ).fetch()
  }, [selectedDialogId])

  const onCompleteDialog = (topicId) => {
    setShowCompleteDialog(false)
    setTopic(topics.find((topic) => topicId === topic.id) || null)
    afterCompleteDialog()
  }

  const afterCompleteDialog = () => {
    if (isEditMode) {
      dispatch({ type: 'setSelectedDialog', selectedDialog: null })
    }
  }

  const isArchived = state === 'ARCHIVED'

  const onReopenDialog = () => {
    setShowReopenDialog(false)
    if (isEditMode) {
      window.setTimeout(() => {
        dispatch({ type: 'setSelectedDialog', selectedDialog: null })
      }, 100)
    }
  }

  const copyChatLink = () => {
    const APP_URL = Meteor.absoluteUrl(routes[ROUTE.SEARCH])
    const link = URI(APP_URL).setQuery('receiver', receiver).toString()
    if (window.navigator?.share && window.navigator.share({url: link})) {
      main.showSuccessSystemNotification(t('dlg:chatLinkCopied'))
    } else {
      main.showWarningSystemNotification(t('dlg:cantCopy'))
    }
    setShowMenu(false)
  }

  const onChangeAgents = () => {
    setShowAgentsDialog(false)
    setShowMenu(false)
  }

  return (
    <div className={mainClass}>
      <div className={css('body')}>
        {!isArchived && (
          <div>
            <div className={css('item')} onClick={handlerClickComplete}>
              {t('dlg:completeChat')}
            </div>
          </div>
        )}
        {isArchived && (
          <div>
            <div className={css('item')} onClick={() => setShowReopenDialog(true)}>
              {t('dlg:reopenChat')}
            </div>
          </div>
        )}
        <div>
          <div className={css('item')} onClick={() => setShowAgentsDialog(true)}>
            {t('dlg:manageAgents')}
          </div>
        </div>
        <div>
          <div className={css('item')} onClick={() => setShowRemindersDialog(true)}>
            {t('dlg:setReminder')}
          </div>
        </div>
        <div>
          <div className={css('item')} onClick={copyChatLink}>
            {t('dlg:shareChat')}
          </div>
        </div>
        {!banResult && (isSupervisor || currentUserPermissions.includes(PERMISSIONS_PAGE.AGENTS_CAN_BAN_CLIENTS)) && (
          <div className={css(classnames('item', '--ban'))} onClick={() => setShowBanClient(true)}>
            {t('dlg:banClient')}
          </div>
        )}
        {banResult && (
          <div className={css(classnames('item', '--ban'))} onClick={onUnbanClient}>
            {t('dlg:unbanClient')}
          </div>
        )}
      </div>
      {showBanClient && (
        <BanClientDialog
          clientProfile={clientProfile}
          receiver={receiver}
          onCancel={() => {
            setShowBanClient(false)
            setShowMenu(false)
          }}
          onBanned={() => {
            updateBanResult()
            setShowBanClient(false)
            setShowMenu(false)
          }}
        />
      )}
      {showCompleteDialog && (
        <CompleteChatDialog
          topic={topic}
          topics={topics}
          topicsGroup={topicsGroup}
          crmFields={crmFields}
          onComplete={onCompleteDialog}
          onCancel={() => {
            setShowCompleteDialog(false)
            setShowMenu(false)
          }}
        />
      )}
      {showReopenDialog && (
        <ReopenChatDialog onReopen={onReopenDialog} onCancel={() => setShowReopenDialog(false)} />
      )}
      {showRemindersDialog && (
        <RemindersDialog reminders={reminders} onCancel={() => {
          setShowRemindersDialog(false)
          setShowMenu(false)
        }} />
      )}
      {showAgentsDialog && (
        <AgentsDialog
          receiver={receiver}
          chatCaption={chatCaption}
          clientProfile={clientProfile}
          agentIds={stuffUsers}
          onCancel={() => {
            setShowAgentsDialog(false)
            setShowMenu(false)
          }}
          onSaved={onChangeAgents}
        />
      )}
    </div>
  )
}

Menu.propTypes = {
  state: string,
  banResult: bool,
  updateBanResult: func.isRequired,
  setShowMenu: func.isRequired,
  crmFields: array,
  subjects: string,
  stuffUsers: array
}
