import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, func, object, string } from 'prop-types'
import { CenterPanel } from '../../Conversations/center-panel'
import { SvgIconArrowLeft } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowLeft'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { store } from '../../../../../../state/dialogs'
import { Avatar } from '../../../../shared/avatar'
import { getDialogName } from '../../../../../../utils/common'
import { useTranslation } from 'react-i18next'
import { useTracker } from '../../../../../../../meteorAdapter';
import Dialogs from '../../../../../../../collections/client/dialogs'
import { Status } from '../../Conversations/left-panel/shared/chat-item/status'
import { Menu } from './menu'

export const MessagesList = ({
  selectedDialogId,
  onAssignedCurrentUser,
  banResult,
  clientId,
  updateBanResult,
  onOpenDetails,
  crmFields
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { dispatch } = useContext(store)
  const [showMenu, setShowMenu] = useState(false)

  const { state, subjects, receiver, stuffInfo, chatCaption, clientProfile, stuffUsers } = useTracker(() => {
    return (
      Dialogs.findOne(selectedDialogId, {
        fields: {
          state: 1,
          subjects: 1,
          receiver: 1,
          stuffInfo: 1,
          chatCaption: 1,
          clientProfile: 1,
          stuffUsers: 1
        }
      }) || {}
    )
  }, [selectedDialogId])

  const onBack = () => {
    dispatch({ type: 'setSelectedDialog', selectedDialog: null })
  }

  const getStaffAvatar = (staffId) => {
    return staffId && stuffInfo ? stuffInfo[staffId]?.avatarUrl : null
  }
  const chatName = getDialogName(chatCaption, clientProfile, receiver, t)

  return (
    <div className={css('main')}>
      <div className={css('header')}>
        <div className={css('side-container')}>
          <div className={css('icon')} onClick={onBack}>
            <SvgIconArrowLeft />
          </div>
        </div>
        <div className={css('user')}>
          <div className={css('side-container')}>
            <div onClick={onOpenDetails} style={{position: 'relative'}}>
              <Avatar url={clientProfile?.avatarUrl || getStaffAvatar(clientId)} colorName={chatName} />
              <Status chat={{ receiver }} isHeader={true} />
            </div>
          </div>
          <div>
            <div className={css('name')}>{chatName}</div>
            {/*<div className={css('status')}>{t(`dlg:agentStatuses.${state}`)}</div>*/}
          </div>
        </div>
        <div className={css('side-container')}>
          <div className={css('menu')} onClick={() => setShowMenu(!showMenu)}>
            <SvgIconMenuDots />
          </div>
          {showMenu && (
            <Menu
              banResult={banResult}
              updateBanResult={updateBanResult}
              setShowMenu={setShowMenu}
              crmFields={crmFields}
              subjects={subjects}
              state={state}
              stuffUsers={stuffUsers}
            />
          )}
        </div>
      </div>
      <div className={css('container')}>
        <CenterPanel
          banResult={banResult}
          updateBanResult={updateBanResult}
          clientId={clientId}
          onChanged={() => {}}
          onAssignedCurrentUser={onAssignedCurrentUser}
          crmFields={crmFields}
        />
      </div>
    </div>
  )
}

MessagesList.propTypes = {
  selectedDialogId: string,
  onAssignedCurrentUser: func,
  banResult: object,
  clientId: string,
  updateBanResult: func,
  onOpenDetails: func,
  crmFields: array
}
