import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { object } from 'prop-types'
import ReminderForm from '../reminder-form'
import RemoveReminderForm from '../remove-reminder-form'
import { DateUtils } from '@flomni/modules/dist/services/date'

const ReminderItem = ({ reminder }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showRemoveForm, setShowRemoveForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)

  return (
    <div className={css('reminder')}>
      {!showRemoveForm && !showEditForm && (
        <div className={css('item')}>
          <div className={css('header')}>
            <div className={css('title')}>
              {reminder.createdBy}
            </div>
            <div className={css('date')}>
              {DateUtils.format(reminder.intendedAt, DateUtils.DATE_TIME_FORMAT)}
            </div>
          </div>
          <div className={css('message')}>{reminder.message}</div>
          {/*<div className={css('controls')}>*/}
          {/*  <SecondaryButton view='light' onClick={() => setShowEditForm(true)}>*/}
          {/*    <SecondaryButtonIcon>*/}
          {/*      <SvgIconEdit />*/}
          {/*    </SecondaryButtonIcon>*/}
          {/*    <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>*/}
          {/*  </SecondaryButton>*/}
          {/*  <SecondaryButton view='light' onClick={() => setShowRemoveForm(true)}>*/}
          {/*    <SecondaryButtonIcon>*/}
          {/*      <SvgIconDelete />*/}
          {/*    </SecondaryButtonIcon>*/}
          {/*    <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>*/}
          {/*  </SecondaryButton>*/}
          {/*</div>*/}
          <div className={css('info')}>
            <div className={css('label')}>{t('dlg:chatWith')}</div>
            <div className={css('name')}>{reminder.dialogId}</div>
          </div>
          <div className={css('info')}>
            <div className={css('label')}>{t('dlg:dueDate')}</div>
            <div className={css('date')}>{DateUtils.format(reminder.intendedAt, DateUtils.DATE_TIME_FORMAT)}</div>
          </div>
        </div>
      )}
      {showEditForm && (
        <ReminderForm
          onSave={() => setShowEditForm(false)}
          onCancel={() => setShowEditForm(false)}
          reminder={reminder}
        />
      )}
      {showRemoveForm && <RemoveReminderForm onCancel={() => setShowRemoveForm(false)} reminder={reminder} />}
    </div>
  )
}

ReminderItem.propTypes = {
  reminder: object
}

export default ReminderItem
